export const degular = {
  wght: {
    min: 200,
    max: 800,
  },
  opsz: {
    min: 6,
    max: 72,
  },
  ital: {
    min: 0,
    max: 1,
  },
};
